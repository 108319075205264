<template>
  <v-card outlined>
    <v-card-title>
      <div>
        <div class="text-muted bold-text text-body-1">
          Views collected for this link
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-skeleton-loader
        class="transparent"
        type="table-thead, table-tbody"
        v-if="loading"
      />
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="getViewsList"
        :page.sync="page"
        class="hide-v-data-footer__select release-table"
        :server-items-length="getTotalViewsCount"
      >
        <template #item.device="{ item }">
          <user-agent-parser
            full
            :default-device="item.device"
          ></user-agent-parser>
        </template>
        <template #item.country="{ item }">
          <country-city :detail="item"></country-city>
        </template>
        <template #item.created_at="{ item }">
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </template>
        <template #footer.page-text>
          <div>
            Updated every minute
            <v-btn class="text-transform-none" @click="loadData()" text>
              <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
              Reload
            </v-btn>
            Total: <b>{{ getTotalViewsCount }}</b>
          </div>
        </template>
        <template #no-data>
          <v-row>
            <v-col cols="12">
              <!-- <no-data first-text="No feedback given... just yet!"></no-data> -->
              <v-col class="no-data-found">
                <inline-svg
                  class="mt-6 svg-icon empty_icon"
                  src="/media/placeholders/all_done.svg"
                />
                <p
                  class="mt-4 empty-title dark-grey-text bold-text text-center"
                >
                  No views yet for this link
                </p>
                <p
                  class="
                    mt-4
                    mb-5
                    bodyFont
                    font-weight-normal
                    text-dark text-center
                  "
                >
                  Views are collected from the public install page
                </p>
              </v-col>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { GET_SHARE_VIEWS_LIST } from "@/store/releases/releases.module";
import { get, isEmpty } from "@/core/services/helper.service";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";

export default {
  components: { CountryCity, UserAgentParser },
  props: {
    shareId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      get,
      isEmpty,
      page: 1,
      loading: false,
      headers: [
        {
          text: "Device",
          align: "start",
          width: "15%",
          sortable: false,
          value: "device",
        },
        {
          text: "Location",
          align: "start",
          width: "15%",
          sortable: false,
          value: "country",
        },
        {
          text: "Date",
          align: "end",
          sortable: false,
          width: "10%",
          value: "created_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getViewsList: "getViewsList",
      appInfo: "getAppInfo",
      getTotalViewsCount: "getTotalViewsCount",
      getTotalViewsList: "getTotalViewsList",
    }),
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalViewsList.length > oldVal * 10) {
          this.setPreviousViewsList(
            this.getTotalViewsList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.getViewsList[this.getViewsList.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousViewsList(this.getTotalViewsList.slice(offset, limit));
      }
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapMutations({
      setPreviousViewsList: "setPreviousViewsList",
    }),
    loadData(lastId) {
      if (this.loading) return;
      this.loading = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: this.shareId,
        lastId: lastId || "",
      };
      this.$store
        .dispatch(GET_SHARE_VIEWS_LIST, payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
