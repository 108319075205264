<template>
  <v-row>
    <template v-if="loadingStats">
      <v-col v-for="i in 4" :key="i" cols="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="6" md="3">
        <stats-card
          icon="mdi-cellphone-arrow-down"
          hover-text="Installs for all public links in this app <br /><br /> We mostly count the unique installs <br /><br />Internal team installs are not counted here<br /> TestFlight installs are not counted<br /><br /> Updated every minute"
          text="External Installs"
          icon-color="blue-grey lighten-2"
          :count="total_installs || 0"
        ></stats-card>
      </v-col>
      <v-col cols="6" md="3">
        <stats-card
          icon="mdi-eye"
          hover-text="Views for all public links in this app <br /><br /> Updated every minute"
          text="Views"
          icon-color="orange lighten-2"
          :count="total_views || 0"
        ></stats-card>
      </v-col>
      <v-col cols="6" md="3">
        <stats-card
          icon="mdi-message"
          hover-text="Feedback received for all public links in this app <br /><br />Internal team feedback are not counted here"
          text="External Feedback"
          icon-color="green lighten-2"
          :count="total_feedback || 0"
        ></stats-card>
      </v-col>
      <v-col cols="6" md="3">
        <stats-card
          icon="mdi-account-multiple-plus-outline"
          hover-text="Acive subscribers for all public links in this app"
          text="Active subscribers (Soon)"
          icon-color="lime lighten-2"
          :count="appInfo.stats ? appInfo.stats.total_subscribers || 0 : '-'"
        ></stats-card>
      </v-col>
    </template>
    <v-col cols="12" md="6">
      <line-charts
        v-if="!busy"
        class="mt-n10"
        display-legend
        :chart-data="downloadInstallChartData || []"
      ></line-charts>
    </v-col>
    <v-col cols="12" md="6">
      <line-charts
        v-if="!busy"
        class="mt-n10"
        display-legend
        :chart-data="feedbackSubscriberChartData || []"
      ></line-charts>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/view/components/Common/StatsCard";
import LineCharts from "@/view/components/Common/Charts/LineCharts";
import {
  GET_SHARE_CHART_LIST,
  GET_SHARE_LINK_STATS,
} from "@/store/releases/releases.module";

export default {
  components: { StatsCard, LineCharts },
  data() {
    return {
      loadingStats: false,
      total_installs: "-",
      total_feedback: "-",
      total_views: "-",
      downloadInstallChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "External Installs",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(144, 164, 174)",
            tension: 0.1,
          },
          {
            label: "Views",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(255, 183, 77)",
            tension: 0.1,
          },
        ],
      },
      feedbackSubscriberChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "External Feedback",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(129, 199, 132)",
            tension: 0.1,
          },
          {
            label: "Subscribers",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(220, 231, 117)",
            tension: 0.1,
          },
        ],
      },
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
  },
  created() {
    if (this.canManageApp) {
      this.getStats();
    } else {
      this.loadingStats = false;
      this.total_installs = "-";
      this.total_feedback = "-";
      this.total_views = "-";
    }
  },
  methods: {
    getStats() {
      if (this.loadingStats) return;
      this.loadingStats = true;
      this.total_installs = "...";
      this.total_feedback = "...";
      this.total_views = "...";
      this.$store
        .dispatch(GET_SHARE_LINK_STATS, { app_id: this.$route.params.app_id })
        .then((response) => {
          if (response) {
            this.total_installs = response.data.installs;
            this.total_feedback = response.data.feedback;
            this.total_views = response.data.views;
          } else {
            this.total_installs = 0;
            this.total_feedback = 0;
          }
          this.loadingStats = false;
          this.getViewsAndInstall();
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loadingStats = false;
          this.total_installs = 0;
          this.total_feedback = 0;
          this.total_views = 0;
        });
    },
    getViewsAndInstall() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: "",
        type: "views_installs",
      };
      this.$store.dispatch(GET_SHARE_CHART_LIST, payload).then((data) => {
        this.setViewsInstallChartData(data.results ? data.results : []);
        this.$nextTick(() => {
          this.busy = false;
          this.getFeedbackAndSubscriber();
        });
      });
    },
    getFeedbackAndSubscriber() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: "",
        type: "feedback_subscribers",
      };
      this.$store.dispatch(GET_SHARE_CHART_LIST, payload).then((data) => {
        this.setFeedbackSubscriberChartData(data.results ? data.results : []);
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    },
    setViewsInstallChartData(results) {
      if (results && results.installs) {
        results.installs.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.downloadInstallChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.downloadInstallChartData.datasets[0].data[month] =
                item.count;
            }
          });
        });
      }
      if (results && results.views) {
        results.views.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.downloadInstallChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.downloadInstallChartData.datasets[1].data[month] =
                item.count;
            }
          });
        });
      }
    },
    setFeedbackSubscriberChartData(results) {
      if (results && results.feedback) {
        results.feedback.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.feedbackSubscriberChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.feedbackSubscriberChartData.datasets[0].data[month] =
                item.count;
            }
          });
        });
      }
      if (results && results.views) {
        results.subscribers.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.feedbackSubscriberChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.feedbackSubscriberChartData.datasets[1].data[month] =
                item.count;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
