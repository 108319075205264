<template>
  <v-row>
    <v-col class="mt-8" cols="12">
      <v-stepper flat non-linear v-model="tab">
        <v-stepper-header flat>
          <v-stepper-step
            complete-icon="mdi-check"
            edit-icon="mdi-android"
            editable
            :complete="tab > 1"
            :step="1"
          >
            Android
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            complete-icon="mdi-check"
            edit-icon="mdi-apple"
            editable
            :complete="tab > 2"
            :step="2"
          >
            iOS
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            complete-icon="mdi-check"
            edit-icon="mdi-settings  "
            editable
            :complete="tab > 3"
            :step="3"
          >
            Settings
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="tab > 4" editable :step="4">
            Review & Submit
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content class="pa-3" :step="1">
            <v-row class="pa-4">
              <v-col cols="6">
                <v-radio-group hide-details v-model="androidOption">
                  <v-radio :value="'latest'">
                    <template #label>
                      <div>
                        Always select the latest release
                        <vue-tooltip
                          icon-color="grey"
                          text="We will automatically update this link with the latest available release"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="'specific_release'">
                    <template #label>
                      <div>
                        Select a specific release
                        <vue-tooltip
                          icon-color="grey"
                          text="Only the selected release can be installed"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="'no'">
                    <template #label>
                      <div>
                        No release
                        <vue-tooltip
                          icon-color="grey"
                          text="No release available for installation"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="androidOption === 'specific_release'" cols="6">
                <v-card class="pa-4">
                  <v-row
                    align="center"
                    v-if="selectedAndroid && selectedAndroid.version"
                  >
                    <v-col class="text-left" cols="6">
                      <div class="bodyFont">
                        <v-img
                          v-if="selectedAndroid.image"
                          :src="selectedAndroid.image"
                          :width="50"
                        ></v-img>
                        <v-icon v-else> mdi-android </v-icon>
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn small @click="selectedAndroid = {}" icon>
                        <v-icon small v-text="'mdi-close'"></v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="pt-0">
                      <div>
                        <span class="text-muted">Version: </span
                        >{{ selectedAndroid.version }}
                      </div>
                      <div>
                        <span class="text-muted">Version Code: </span>
                        {{ selectedAndroid.version_code }}
                      </div>
                      <div>
                        <span class="text-muted">Size: </span>
                        {{ formatBytes(selectedAndroid.size || 0) }}
                      </div>
                      <div>
                        <span class="text-muted"> Released: </span>
                        <vue-hoverable-date
                          :date="selectedAndroid.created_at"
                        ></vue-hoverable-date>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="!selectedAndroid || !selectedAndroid.version">
                    <v-col cols="12" class="text-center">
                      <v-btn
                        color="primary"
                        class="text-transform-none"
                        @click="openSelectedReleaseForm('android')"
                      >
                        Select a release
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  @click="proceedToIOS"
                  color="primary"
                  class="text-transform-none"
                  depressed
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="2">
            <v-row>
              <v-col cols="12">
                <div class="bodyFont bold-text text-left">
                  Who do you want to share this app with?
                </div>
              </v-col>
              <v-col class="" cols="6">
                <v-card
                  :class="shareType === 'internal' ? 'successBorder' : ''"
                  @click="shareType = 'internal'"
                  outlined
                  class="cursor-pointer"
                >
                  <v-card-title>Internal testers</v-card-title>
                  <v-card-text>
                    <ul class="text-left">
                      <li>Best to start with internal testers</li>
                      <li>
                        Provisioning profiles (Ad-hoc or Development)
                        <ul>
                          <li>Testers must be added to the profile</li>
                          <li>Up to 100 devices (From Apple)</li>
                        </ul>
                      </li>
                      <li>Install directly from link</li>
                      <li>Get feedback from your public install page</li>
                      <li>External testers subscribe for updates</li>
                      <li>Full analytics</li>
                    </ul>
                  </v-card-text>
                </v-card>
                <v-radio-group
                  v-if="shareType === 'internal'"
                  class="mt-3"
                  v-model="iosOption"
                >
                  <v-radio :value="'latest'">
                    <template #label>
                      <div>
                        Always select the latest release
                        <vue-tooltip
                          icon-color="grey"
                          text="We will automatically update this link with the latest available release"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="'specific_release'">
                    <template #label>
                      <div>
                        Select a specific release
                        <vue-tooltip
                          icon-color="grey"
                          text="Only the selected release can be installed"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :label="`No release`" :value="'no'">
                    <template #label>
                      <div>
                        No release
                        <vue-tooltip
                          icon-color="grey"
                          text="No release available for installation"
                        ></vue-tooltip>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <v-card v-if="iosOption == 'specific_release'" class="pa-4">
                  <v-row
                    align="center"
                    v-if="selectedIos && selectedIos.version"
                  >
                    <v-col cols="6" class="text-left">
                      <div class="bodyFont">
                        <v-img
                          v-if="selectedIos.image"
                          :src="selectedIos.image"
                          :width="50"
                        ></v-img>
                        <v-icon v-else> mdi-apple </v-icon>
                      </div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn small @click="selectedIos = {}" icon>
                        <v-icon small v-text="'mdi-close'"></v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="pt-0">
                      <div>
                        <span class="text-muted">Version: </span
                        >{{ selectedIos.version }}
                      </div>
                      <div>
                        <span class="text-muted">Version Code: </span>
                        {{ selectedIos.version_code }}
                      </div>
                      <div>
                        <span class="text-muted">Size: </span>
                        {{ formatBytes(selectedIos.size || 0) }}
                      </div>
                      <div>
                        <span class="text-muted"> Released: </span>
                        <vue-hoverable-date
                          :date="selectedIos.created_at"
                        ></vue-hoverable-date>
                      </div>
                      <!-- <div>
                        <span class="text-muted"> Developer: </span>
                        {{ selectedIos.developer }}
                      </div> -->
                    </v-col>
                  </v-row>
                  <v-row v-if="!selectedIos || !selectedIos.version">
                    <v-col cols="12" class="text-center">
                      <v-btn
                        color="primary"
                        class="text-transform-none"
                        @click="openSelectedReleaseForm('ios')"
                      >
                        Select a release
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  :class="shareType === 'external' ? 'successBorder' : ''"
                  @click="shareType = 'external'"
                  outlined
                >
                  <v-card-title>External testers</v-card-title>
                  <v-card-text>
                    <ul class="text-left">
                      <li>Best when you are ready for external testers</li>
                      <li>TestFlight review required</li>
                      <li>Up to 10,000 external testers</li>
                      <li>Get feedback from TestFlight</li>
                      <li>Get feedback from the public install page</li>
                      <li>Install from TestFlight app</li>
                      <li>Managed by TestFlight</li>
                      <li>Page views analytics only</li>
                    </ul>
                  </v-card-text>
                </v-card>
                <div v-if="shareType === 'external'">
                  <div class="text-left mt-4 mb-2 bodyFont bold-text">
                    TestFlight public group link
                    <vue-tooltip
                      icon-color="grey"
                      text="Make sure to enable the public link. To learn more, navigate to developer.apple.com -> App Store Connect -> Your app -> TestFlight -> External Testing"
                    ></vue-tooltip>
                  </div>
                  <v-text-field
                    v-model="testflightLink"
                    v-bind="veeValidate('testflight link', '')"
                    outlined
                    dense
                    class="py-0 my-0"
                    v-validate="'required|url'"
                    placeholder="https://testflight.apple.com/join/pC3Sf5yp"
                    hint="Check developer.apple.com -> TestFlight -> External Testing"
                  >
                    <template #append>
                      <v-btn
                        @click.stop="validateTestFlightLink"
                        small
                        :loading="busy"
                        :disabled="errors.any()"
                        class="text-transform-none mb-2"
                        :color="validated ? 'success' : 'primary'"
                      >
                        <v-icon
                          small
                          left
                          v-if="validated"
                          v-text="'mdi-check'"
                        ></v-icon>
                        {{ validated ? "Validated" : "Validate" }}
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  @click="tab = 1"
                  color="primary"
                  class="text-transform-none mr-2"
                  depressed
                  >Previous</v-btn
                >
                <v-btn
                  @click="proceedToSettings"
                  color="primary"
                  class="text-transform-none"
                  depressed
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="3">
            <v-row class="pa-4">
              <v-col cols="4">
                <div class="text-left mt-0 mb-2 bodyFont bold-text">
                  Expiry date
                  <vue-tooltip
                    icon-color="grey"
                    text="Automatically disable the link. You can change the option at any time before it expires but once it does, you cannot make active again"
                  ></vue-tooltip>
                </div>
                <v-select
                  filled
                  dense
                  :items="expiryDays"
                  item-value="value"
                  item-text="title"
                  v-model="expire_date"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="formValues.settings.allow_feedback"
                  label="Enable feedback"
                >
                  <template #label>
                    <div>
                      Enable Feedback
                      <vue-tooltip
                        icon-color="grey"
                        text="Enable users to share their feedback"
                      ></vue-tooltip>
                    </div>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="4">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-switch
                        v-model="formValues.settings.allow_subscribing"
                        disabled
                        label="Enable subscribing"
                      >
                        <template #label>
                          <div>
                            Enable subscribing
                            <vue-tooltip
                              icon-color="grey"
                              text="Enable users to subscribe for release updates"
                            ></vue-tooltip>
                          </div>
                        </template>
                      </v-switch>
                    </span>
                  </template>
                  <span>
                    Coming Soon. Enabling external testers to subscribe for
                    release updates from the install page
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="6">
                <div class="bodyFont text-left">
                  Install instructions
                  <span
                    @click="copyFromAppInstruction"
                    class="primary--text text-right cursor-pointer caption"
                  >
                    Copy from global instructions
                  </span>
                  <vue-tooltip
                    icon-color="grey"
                    text="Install instructions will be display in the public install page"
                  ></vue-tooltip>
                </div>
                <v-textarea
                  filled
                  v-model="formValues.instructions"
                  rows="5"
                  v-validate="'max:600'"
                  counter="600"
                  placeholder="Any special instructions for the testers to start testing this app?"
                  v-bind="veeValidate('instructions', '')"
                >
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <div class="bodyFont text-left">
                  Internal notes for team
                  <vue-tooltip
                    icon-color="grey"
                    text="These notes are only shared within your team and not publicly"
                  ></vue-tooltip>
                </div>
                <v-textarea
                  filled
                  v-model="formValues.note"
                  rows="5"
                  v-validate="'max:600'"
                  counter="600"
                  placeholder="These notes will be only visible within your team and it can be anything related to this link"
                  v-bind="veeValidate('note', '')"
                >
                </v-textarea>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  @click="tab = 2"
                  color="primary"
                  class="text-transform-none mr-2"
                  depressed
                  >Previous</v-btn
                >
                <v-btn
                  @click="tab = 4"
                  color="primary"
                  class="text-transform-none"
                  depressed
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="4">
            <v-row justify="start">
              <v-col class="text-left" cols="4">
                <div class="bodyFont bold-text mb-3">iOS</div>
                <div v-if="shareType === 'external'">
                  <a
                    v-if="testflightLink"
                    :href="testflightLink ? testflightLink : ''"
                    target="_blank"
                  >
                    TestFlight URL
                  </a>
                </div>
                <div v-else>
                  <div
                    v-if="
                      iosOption == 'specific_release' && !isEmpty(selectedIos)
                    "
                  >
                    <v-card class="pa-3" outlined>
                      <v-col class="pt-0">
                        <div>
                          <v-img
                            v-if="selectedIos.image"
                            :src="selectedIos.image"
                            :width="50"
                          ></v-img>
                          <v-icon v-else> mdi-apple </v-icon>
                        </div>
                        <div>
                          <span class="text-muted">Version: </span
                          >{{ selectedIos.version }}
                        </div>
                        <div>
                          <span class="text-muted">Version Code: </span>
                          {{ selectedIos.version_code }}
                        </div>
                        <div>
                          <span class="text-muted">Size: </span>
                          {{ formatBytes(selectedIos.size || 0) }}
                        </div>
                        <div>
                          <span class="text-muted"> Released: </span>
                          <vue-hoverable-date
                            :date="selectedIos.created_at"
                          ></vue-hoverable-date>
                        </div>
                        <!-- <div>
                          <span class="text-muted"> Developer: </span>
                          {{ selectedIos.developer }}
                        </div> -->
                      </v-col>
                    </v-card>
                  </div>
                  <div v-else>
                    {{ iosOption == "no" ? "No release" : "Latest" }}
                  </div>
                </div>
              </v-col>
              <v-col class="text-left" cols="4">
                <div class="bodyFont bold-text mb-3">Android</div>
                <div>
                  <div
                    v-if="
                      androidOption == 'specific_release' &&
                      !isEmpty(selectedAndroid)
                    "
                  >
                    <v-card class="pa-3" outlined>
                      <v-col class="pt-0">
                        <div>
                          <v-img
                            v-if="selectedAndroid.image"
                            :src="selectedAndroid.image"
                            :width="50"
                          ></v-img>
                          <v-icon v-else> mdi-android </v-icon>
                        </div>

                        <div>
                          <span class="text-muted">Version: </span
                          >{{ selectedAndroid.version }}
                        </div>
                        <div>
                          <span class="text-muted">Version Code: </span>
                          {{ selectedAndroid.version_code }}
                        </div>
                        <div>
                          <span class="text-muted">Size: </span>
                          {{ formatBytes(selectedAndroid.size || 0) }}
                        </div>
                        <div>
                          <span class="text-muted"> Released: </span>
                          <vue-hoverable-date
                            :date="selectedAndroid.created_at"
                          ></vue-hoverable-date>
                        </div>
                        <!-- <div>
                          <span class="text-muted"> Developer: </span>
                          {{ selectedAndroid.developer }}
                        </div> -->
                      </v-col>
                    </v-card>
                  </div>
                  <div v-else>
                    {{ androidOption == "no" ? "No release" : "Latest" }}
                  </div>
                </div>
              </v-col>
              <v-col class="text-left" cols="4">
                <div class="mb-2">
                  Enable feedback:
                  <v-icon
                    :color="
                      formValues.settings.allow_feedback ? 'success' : 'error'
                    "
                    right
                    v-text="
                      formValues.settings.allow_feedback
                        ? 'mdi-check'
                        : 'mdi-close'
                    "
                  ></v-icon>
                </div>
                <div class="mb-2">
                  Enable subscribing:
                  <v-icon
                    :color="
                      formValues.settings.allow_subscribing
                        ? 'success'
                        : 'error'
                    "
                    right
                    v-text="
                      formValues.settings.allow_subscribing
                        ? 'mdi-check'
                        : 'mdi-close'
                    "
                  ></v-icon>
                </div>
                <div>
                  Expire:
                  <span class="ml-3">{{
                    expire_date
                      ? `${expire_date}${expire_date === 1 ? " Day" : " days"}`
                      : "-"
                  }}</span>
                </div>
              </v-col>
              <v-col class="text-left" cols="6">
                <div class="bodyFont bold-text mb-3">Instructions</div>
                <div style="white-space: pre-line">
                  {{ formValues.instructions || "-" }}
                </div>
              </v-col>
              <v-col class="text-left" cols="6">
                <div class="bodyFont bold-text mb-3">Internal notes</div>
                <div style="white-space: pre-line">
                  {{ formValues.note || "-" }}
                </div>
              </v-col>
              <v-col class="text-right" cols="12">
                <v-btn
                  @click="tab = 3"
                  color="primary"
                  class="text-transform-none mr-2"
                  depressed
                  >Previous</v-btn
                >
                <v-btn
                  :loading="loading"
                  @click="submitForm"
                  color="primary"
                  class="text-transform-none"
                  depressed
                >
                  {{
                    actionData && actionData.id
                      ? "Update link"
                      : "Generate link"
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <Modal
      v-model="selectedReleaseDialog"
      height="450"
      @click="selectedReleaseDialog = false"
    >
      <template #message>
        <div v-if="selectedReleaseDialog" class="mb-15 pt-5 pb-15">
          <div class="bodyFont mb-2">
            Select a specific {{ releasePlatform }} release
          </div>
          <release-auto-complete
            v-if="releasePlatform === 'android'"
            platform="android"
            :app_id="appId"
            :key="'android'"
            :first-load="firstLoadAndroid"
            @loaded="firstLoadAndroid = false"
            v-model="selectedAndroid"
          ></release-auto-complete>
          <release-auto-complete
            v-else
            platform="ios"
            :app_id="appId"
            class="mt-n4"
            :first-load="firstLoadIOS"
            @loaded="firstLoadIOS = false"
            :key="'ios'"
            v-model="selectedIos"
          ></release-auto-complete>
        </div>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import ReleaseAutoComplete from "@/view/components/Common/ReleaseAutoComplete";
import { isEmpty, formatBytes } from "@/core/services/helper.service.js";
import veeValidate from "@/mixins/veeValidate";
import {
  CREATE_SHAREABLE_LINK,
  UPDATE_SHAREABLE_LINK,
  VALIDATE_TESTFLIGHT_LINK,
} from "@/store/releases/releases.module";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    actionData: {
      type: Object,
      default() {
        return {
          release: null,
          instructions: "",
          settings: {
            expire: 0,
            public_access: true,
            allow_feedback: true,
            allow_subscribing: false,
          },
        };
      },
    },
  },
  components: {
    ReleaseAutoComplete,
  },
  mixins: [veeValidate],
  data() {
    return {
      tab: 1,
      isEmpty,
      formatBytes,
      androidOption: "latest",
      expiryDays: [
        { title: "No expiry date", value: 0 },
        { title: "Expire in 1 day", value: 1 },
        { title: "Expire in 2 days", value: 2 },
        { title: "Expire in 3 days", value: 3 },
        { title: "Expire in 4 days", value: 4 },
        { title: "Expire in 5 days", value: 5 },
        { title: "Expire in 6 days", value: 6 },
        { title: "Expire in 7 days", value: 7 },
        { title: "Expire in 8 days", value: 8 },
        { title: "Expire in 9 days", value: 9 },
        { title: "Expire in 10 days", value: 10 },
        { title: "Expire in 11 days", value: 11 },
        { title: "Expire in 12 days", value: 12 },
        { title: "Expire in 13 days", value: 13 },
        { title: "Expire in 14 days", value: 14 },
        { title: "Expire in 15 days", value: 15 },
        { title: "Expire in 16 days", value: 16 },
        { title: "Expire in 17 days", value: 17 },
        { title: "Expire in 18 days", value: 18 },
        { title: "Expire in 19 days", value: 19 },
        { title: "Expire in 20 days", value: 20 },
        { title: "Expire in 21 days", value: 21 },
        { title: "Expire in 22 days", value: 22 },
        { title: "Expire in 23 days", value: 23 },
        { title: "Expire in 24 days", value: 24 },
        { title: "Expire in 25 days", value: 25 },
        { title: "Expire in 26 days", value: 26 },
        { title: "Expire in 27 days", value: 27 },
        { title: "Expire in 28 days", value: 28 },
        { title: "Expire in 29 days", value: 29 },
        { title: "Expire in 30 days", value: 30 },
      ],
      iosOption: "latest",
      validated: false,
      expire_date: 0,
      shareType: "internal",
      testflightLink: "",
      allow_expire: false,
      selectedAndroid: null,
      selectedReleaseDialog: false,
      releasePlatform: false,
      busy: false,
      firstLoadIOS: true,
      firstLoadAndroid: true,
      selectedIos: null,
      loading: false,
      formValues: {
        android: {
          id: "",
          latest: false,
        },
        ios: {
          id: "",
          latest: false,
        },
        instructions: "",
        settings: {
          expire: 0,
          allow_feedback: true,
          allow_subscribing: false,
        },
      },
      allowAutoUpdate: false,
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getAppInfo",
    }),
  },
  watch: {
    testflightLink() {
      this.validated = false;
    },
    selectedIos() {
      this.selectedReleaseDialog = false;
    },
    selectedAndroid() {
      this.selectedReleaseDialog = false;
    },
    androidOption(val) {
      if (val !== "specific_release") {
        this.selectedAndroid = null;
      }
      if (val === "specific_release" && !this.selectedAndroid) {
        this.openSelectedReleaseForm("android");
      }
    },
    iosOption(val) {
      if(val !== "specific_release") {
        this.selectedIos = null;
      }
      if (val === "specific_release" && !this.selectedIos) {
        this.openSelectedReleaseForm("ios");
      }
    },
    shareType() {
      this.iosOption = "no";
      this.selectedIos = null;
    },
  },
  created() {
    this.appId = this.$route.params.app_id;
    this.populateInitialData();
  },
  methods: {
    ...mapMutations({
      setShareLinkUpdatedData: "setShareLinkUpdatedData",
    }),
    openSelectedReleaseForm(platform) {
      this.releasePlatform = platform;
      this.selectedReleaseDialog = true;
    },
    populateInitialData() {
      if (this.actionData && this.actionData.id) {
        this.formValues = { ...this.actionData };
      }

      if (this.actionData && this.actionData.id) {
        this.expire_date = this.actionData.settings.expire || 0;

        const android = this.actionData.android;
        if (this.actionData.settings.latest_android) {
          this.androidOption = "latest";
        } else if (!android || isEmpty(android)) {
          this.androidOption = "no";
        } else {
          this.selectedAndroid = android;
        }

        if (this.actionData.ios && this.actionData.ios.testflight) {
          this.shareType = "external";
          this.testflightLink = this.actionData.ios.testflight;
        }

        if (
          !this.actionData.settings.latest_ios &&
          !this.actionData.ios.testflight
        ) {
          this.selectedIos = this.actionData.ios || null;
        }
        if (this.selectedAndroid) {
          this.androidOption = "specific_release";
        }

        if (this.actionData.settings.latest_ios) {
          this.iosOption = "latest";
        } else if (this.selectedIos) {
          this.iosOption = "specific_release";
        } else {
          this.iosOption = "no";
        }
      }
    },
    copyFromAppInstruction() {
      this.formValues.instructions = this.appInfo.settings.global_instructions;
    },
    disableSubmit() {
      if ([this.iosOption, this.androidOption].includes("no")) {
        return true;
      }
      if (
        [this.iosOption, this.androidOption].includes("specific_release") &&
        isEmpty(this.selectedIos) &&
        isEmpty(this.androidOption)
      ) {
        return true;
      }
      return false;
    },
    submitForm() {
      if (
        (this.androidOption === "specific_release" &&
          isEmpty(this.selectedAndroid)) ||
        (this.iosOption === "specific_release" && isEmpty(this.selectedIos)) ||
        (this.shareType === "external" && !this.testflightLink)
      ) {
        this.notifyErrorMessage("At least one platform must be selected");
        return;
      }

      if (
        this.androidOption === "no" &&
        this.iosOption === "no" &&
        this.shareType !== "external"
      ) {
        this.notifyErrorMessage("At least one platform must be selected");
        return;
      }

      if (this.actionData && this.actionData.id) {
        this.updateShareAbleLink();
      } else {
        this.generateShareableLink();
      }
    },
    proceedToIOS() {
      if (
        this.androidOption === "specific_release" &&
        isEmpty(this.selectedAndroid)
      ) {
        this.notifyErrorMessage("Please select a valid release option");
      } else {
        this.tab = 2;
      }
    },
    proceedToSettings() {
      if (this.iosOption === "specific_release" && isEmpty(this.selectedIos)) {
        this.notifyErrorMessage("Please select a valid release option");
      } else if (
        this.shareType === "external" &&
        !this.testflightLink &&
        !this.validated
      ) {
        this.notifyErrorMessage("Please enter a valid TestFlight link");
      } else {
        this.tab = 3;
      }
    },
    generateShareableLink() {
      if (this.loading) return;
      this.loading = true;
      if (this.androidOption === "specific_release") {
        this.formValues.android.id = this.selectedAndroid.id;
      } else if (this.androidOption === "latest") {
        this.formValues.android.latest = true;
      }
      this.formValues.settings.expire = this.expire_date || 0;
      if (this.shareType === "external") {
        this.formValues.ios.testflight_link = this.testflightLink;
      } else {
        if (this.iosOption === "specific_release") {
          this.formValues.ios.id = this.selectedIos.id;
        } else if (this.iosOption === "latest") {
          this.formValues.ios.latest = true;
        }
      }
      this.formValues.app_id = this.$route.params.app_id;
      this.$store
        .dispatch(CREATE_SHAREABLE_LINK, this.formValues)
        .then((response) => {
          this.loading = false;
          this.$emit("close");
          this.$emit("refresh", response);
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    validateTestFlightLink() {
      const dataToPost = {
        link: this.testflightLink,
        app_id: this.$route.params.app_id,
      };
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(VALIDATE_TESTFLIGHT_LINK, dataToPost)
        .then(() => {
          this.busy = false;
          this.validated = true;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    updateShareAbleLink() {
      if (this.loading) return;
      this.loading = true;
      this.formValues.android = {
        id: "",
        latest: false,
      };
      this.formValues.ios = {
        id: "",
        latest: false,
      };
      this.formValues.settings.expire = this.expire_date;
      if (this.formValues.settings.allow_subscribing === "false") {
        this.formValues.settings.allow_subscribing = false;
      }
      if (this.formValues.settings.allow_subscribing === "true") {
        this.formValues.settings.allow_subscribing = true;
      }
      if (this.androidOption === "specific_release") {
        this.formValues.android.id = this.selectedAndroid.id;
      } else if (this.androidOption === "latest") {
        this.formValues.android.latest = true;
      }

      if (this.shareType === "external") {
        this.formValues.ios.testflight_link = this.testflightLink;
      } else {
        if (this.iosOption === "specific_release") {
          this.formValues.ios.id = this.selectedIos.id;
        } else if (this.iosOption === "latest") {
          this.formValues.ios.latest = true;
        }
      }
      this.formValues.app_id = this.$route.params.app_id;

      this.$store
        .dispatch(UPDATE_SHAREABLE_LINK, this.formValues)
        .then((response) => {
          this.loading = false;
          this.setShareLinkUpdatedData(this.formValues);
          this.notifyUserMessage(response.message);
          this.$emit("close");
          this.$emit("updated", response);
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped>
.successBorder {
  border: darkgreen 1px solid;
}
</style>
