<template>
  <div>
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <v-card outlined>
          <v-row justify="center">
            <v-col cols="12" md="11">
              <v-row>
                <v-col
                  :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ''"
                  class="text-left"
                  cols="6"
                  md="6"
                >
                  <div class="headingTitle">
                    Share
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <span v-on="on">
                          <v-chip x-small>BETA</v-chip>
                        </span>
                      </template>
                      <span>
                        This feature is in BETA mode, please report if you find
                        any issue or have feedback for us!
                      </span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col class="text-right" cols="6" v-if="canManageApp">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-switch
                          hide-details
                          style="float: right"
                          @click="updatePublicAccess"
                          v-model="public_access"
                          label="Public Access"
                        ></v-switch>
                      </span>
                    </template>
                    <span>
                      Enable or disable public access for all public links in
                      this app
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ''"
                  class="text-center"
                  cols="10"
                >
                  Share this app with everyone to get their feedback directly
                  from the public install page.<br />
                  External testers will not be required to have an account with
                  TestApp.io or install our app.<br /><br />
                  At any time, set the page status to maintenance or coming soon
                  to disable new installs without losing the link.
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <share-stats-chart></share-stats-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card height="100%" outlined>
          <v-card-title>
            <div>
              <div class="font-size-h3">Install links</div>
              <div class="text-muted bold-text text-body-1">
                {{
                  canManageApp
                    ? "Share your app with everyone for testing and feedback"
                    : ""
                }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              :disabled="!canManageApp"
              class="text-transform-none"
              @click="openSetting()"
            >
              <v-icon v-text="'mdi-plus'"></v-icon>
              New link
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-skeleton-loader
              class="transparent"
              type="table-thead, table-tbody"
              v-if="loading"
            />

            <v-data-table
              v-if="!loading && canManageApp"
              :headers="headers"
              :expanded="expanded"
              :items="getShareableLinks || []"
              :page.sync="page"
              class="hide-v-data-footer__select release-table"
              :server-items-length="getTotalShareableLinksCount || 0"
              @click:row="expendRow"
            >
              <template #item.android="{ item }">
                <div v-if="item.android.status">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          small
                          left
                          :color="
                            $options.RELEASE_STATUS[item.android.status].color
                          "
                          v-text="'mdi-android'"
                        ></v-icon>
                        {{ item.settings.latest_android ? "Latest" : "Custom" }}
                      </span>
                    </template>
                    <span>
                      <ul>
                        <li>
                          Status:
                          {{
                            $options.RELEASE_STATUS[item.android.status].title
                          }}
                        </li>
                        <li>
                          Version:
                          {{ item.android.version }}
                        </li>
                        <li>
                          Version Code:
                          {{ item.android.version_code }}
                        </li>
                        <li>
                          Size:
                          {{ formatBytes(item.android.size) }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
                <!-- No release selected -->
                <div v-else>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon small left v-text="'mdi-android'"></v-icon>
                      <span v-on="on"> - </span>
                    </template>
                    <span> No release selected </span>
                  </v-tooltip>
                </div>
              </template>

              <template #item.ios="{ item }">
                <div style="display: flex" v-if="item.ios.testflight">
                  <v-icon
                    small
                    left
                    color="green"
                    v-text="'mdi-apple'"
                  ></v-icon>
                  <span>
                    <vue-tooltip
                      display-text="TestFlight"
                      :text="item.ios.testflight"
                    >
                    </vue-tooltip>
                  </span>
                </div>

                <div v-else-if="item.ios.status">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <span v-on="on">
                        <v-icon
                          small
                          left
                          :color="
                            $options.RELEASE_STATUS[item.ios.status].color
                          "
                          v-text="'mdi-apple'"
                        ></v-icon>
                        {{ item.settings.latest_ios ? "Latest" : "Custom" }}
                      </span>
                    </template>
                    <span>
                      <ul>
                        <li>
                          Status:
                          {{ $options.RELEASE_STATUS[item.ios.status].title }}
                        </li>
                        <li>
                          Version:
                          {{ item.ios.version }}
                        </li>
                        <li>
                          Version Code:
                          {{ item.ios.version_code }}
                        </li>
                        <li>
                          Size:
                          {{ formatBytes(item.ios.size) }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
                <!-- No release selected -->
                <div v-else>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon small left v-text="'mdi-android'"></v-icon>
                      <span v-on="on"> - </span>
                    </template>
                    <span> No release selected </span>
                  </v-tooltip>
                </div>
              </template>

              <template #item.views="{ item }">
                <v-btn
                  color="primary"
                  @click.stop="openAnalyticsDrawer(item, 0)"
                  icon
                >
                  <b> {{ item.stats.views || "0" }}</b>
                </v-btn>
              </template>
              <template #item.install="{ item }">
                <v-btn
                  color="primary"
                  @click.stop="openAnalyticsDrawer(item, 1)"
                  icon
                >
                  <b>{{ item.stats.installs || "0" }}</b>
                </v-btn>
              </template>
              <template #item.feedback="{ item }">
                <v-btn
                  color="primary"
                  @click.stop="openAnalyticsDrawer(item, 2)"
                  icon
                >
                  <b>{{ item.stats.feedback || "0" }}</b>
                </v-btn>
              </template>
              <template #item.subscribe="{ item }">
                <v-btn
                  color="primary"
                  @click.stop="openAnalyticsDrawer(item, 3)"
                  icon
                >
                  <b>{{ item.stats.subscribers || "0" }}</b>
                </v-btn>
              </template>
              <template #item.copy_link="{ item }">
                <v-btn @click.stop="openLink(item.uid)" color="primary" icon>
                  <v-icon color="primary" small v-text="'mdi-eye'"></v-icon>
                </v-btn>
              </template>
              <template #item.expiry="{ item }">
                <div v-if="item.expire_at">
                  <vue-hoverable-date
                    :date="item.expire_at"
                  ></vue-hoverable-date>
                </div>
                <div v-else>-</div>
              </template>
              <template #item.status="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-icon
                        :color="$options.STATUS[item.status].color"
                        v-text="'mdi-radiobox-blank'"
                      ></v-icon>
                    </span>
                  </template>
                  <span>
                    {{ $options.STATUS[item.status].title }}
                  </span>
                </v-tooltip>
              </template>
              <template #item.action="{ item }">
                <div class="center-el">
                  <v-btn icon>
                    <v-icon v-text="'mdi-chevron-down'"></v-icon>
                  </v-btn>
                  <action-dropdown>
                    <template #list>
                      <v-list-item
                        v-if="item.status !== 2"
                        @click="openEditDialog(item)"
                      >
                        <v-icon small>edit</v-icon>
                        edit
                      </v-list-item>
                      <v-list-item
                        v-if="item.status !== 2"
                        @click="openChangeStatus(item)"
                      >
                        <v-icon small>mdi-switch</v-icon>
                        Change status
                      </v-list-item>
                      <v-list-item @click="openDeleteDialog(item)">
                        <v-icon small left>delete</v-icon>
                        Delete
                      </v-list-item>
                    </template>
                  </action-dropdown>
                </div>
              </template>
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                  <v-card tile flat>
                    <v-card-text class="pb-4">
                      <v-row v-if="item.ios && item.ios.testflight">
                        <v-col cols="12">
                          <b>TestFlight:</b>
                          <a :href="item.ios.testflight" target="_blank">
                            {{ item.ios.testflight }}
                          </a>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="pb-0" cols="4">
                          <div class="mb-2">
                            <b>Enable feedback:</b>
                            <v-icon
                              :color="
                                item.settings.allow_feedback
                                  ? 'success'
                                  : 'error'
                              "
                              right
                              v-text="
                                item.settings.allow_feedback
                                  ? 'mdi-check'
                                  : 'mdi-close'
                              "
                            ></v-icon>
                          </div>
                          <div class="mb-2">
                            <b>Enable subscribing:</b>
                            <v-icon
                              :color="
                                item.settings.allow_subscribing
                                  ? 'success'
                                  : 'error'
                              "
                              right
                              v-text="
                                item.settings.allow_subscribing
                                  ? 'mdi-check'
                                  : 'mdi-close'
                              "
                            ></v-icon>
                          </div>
                          <div class="mb-2">
                            <b>Created:</b>
                            <vue-hoverable-date
                              :date="item.created_at"
                            ></vue-hoverable-date>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mb-2">
                            <b>Custom message:</b>
                            <div>
                              {{ item.settings.custom_message || "-" }}
                            </div>
                          </div>
                          <div class="mb-2">
                            <b>Internal notes:</b>
                            <div>
                              <vue-readmore
                                :text="item.note || '-'"
                                :length="150"
                              ></vue-readmore>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="mb-2">
                            <b>Status:</b>
                            <div
                              :class="
                                $options.STATUS[item.status].color + '--text'
                              "
                            >
                              {{ $options.STATUS[item.status].text }}
                            </div>
                          </div>
                          <div class="mb-2">
                            <b>Instructions:</b>
                            <div>
                              <vue-readmore
                                :text="item.instructions || '-'"
                                :length="200"
                              ></vue-readmore>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
              </template>
              <template #no-data>
                <v-row>
                  <v-col class="no-data-found" cols="12">
                    <no-data
                      first-text="No install links for this app"
                      second-text="Share your app with everyone for testing and feedback"
                    ></no-data>
                  </v-col>
                </v-row>
              </template>
              <template #footer.page-text>
                <v-btn class="text-transform-none" @click="loadData()" text>
                  <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
                  Reload
                </v-btn>
                Total: <b>{{ getTotalShareableLinksCount }}</b>
              </template>
            </v-data-table>

            <no-data
              v-else-if="!canManageApp"
              first-text="No install links"
              second-text="You don't have the permission to view or manage the public share links"
            ></no-data>
          </v-card-text>
          <Modal
            v-model="updateShareStatusModal"
            @close="updateShareStatusModal = false"
          >
            <template #message>
              <udpate-share-link-status
                v-if="updateShareStatusModal"
                :selected-detail="selectedData"
                @close="updateShareStatusModal = false"
              ></udpate-share-link-status>
            </template>
          </Modal>
          <Modal v-model="openDeleteModal" @close="openDeleteModal = false">
            <template #message>
              <v-row>
                <v-col class="mt-4 font-size-h3 bold-text" cols="12">
                  Delete custom share link
                </v-col>
                <v-col class="bodyFont" cols="12">
                  Are you sure you want to delete this link?

                  <v-alert
                    class="mt-3 bodyFont"
                    border="left"
                    color="info"
                    icon="info"
                    outlined
                  >
                    By deleting this URL will make it immediately unavailable
                    for everyone
                    <br /><br />
                    You have the option to modify the releases to stop the
                    installs instead of deleting the link
                  </v-alert>
                  <p>There's no undo for this action.</p>
                </v-col>
                <v-col class="text-right" cols="12">
                  <v-btn
                    text
                    class="text-transform-none"
                    color="primary"
                    :disabled="busy"
                    @click="openDeleteModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    depressed
                    class="text-transform-none"
                    color="primary"
                    :loading="busy"
                    @click="deleteShareableLink"
                  >
                    Yes
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </Modal>
          <Modal
            v-model="openShareLinkModal"
            @close="openShareLinkModal = false"
          >
            <template #message>
              <share-link-modal :share-id="shareId"></share-link-modal>
            </template>
          </Modal>
          <SidePanel
            :temporary="false"
            :permanent="false"
            v-model="showAnalyticsDrawer"
            @close="closeModal"
            width="1000px"
          >
            <share-analytics
              v-if="showAnalyticsDrawer"
              :share-id="selectedData.id"
              :selected-tab="defaultSelectedTab"
            ></share-analytics>
          </SidePanel>
          <Modal v-model="openGenerateLinkForm" width="850" @close="closeModal">
            <template #message>
              <share-link-stepper
                v-if="openGenerateLinkForm"
                @close="closeModal"
                @refresh="afterSuccess"
                :key="openGenerateLinkForm"
                @updated="afterUpdated"
                :action-data="selectedData"
              ></share-link-stepper>
            </template>
          </Modal>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.Invited {
  color: #fa6400;
}

.Suspended {
  color: #e02020;
}
</style>

<script>
import { isEmpty, formatBytes, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import {
  DELETE_SHAREABLE_LINK,
  GET_SHARE_LINK_LIST,
  UPDATE_APP_PUBLIC_STATUS,
} from "@/store/releases/releases.module";
import ShareLinkModal from "@/view/components/Releases/ShareLinkModal";
import ShareLinkStepper from "@/view/components/Releases/ShareLinkStepper";
import VueReadmore from "@/view/components/Common/VueReadmore";
import ShareAnalytics from "@/view/components/Releases/ShareAnalytics";
import ShareStatsChart from "@/view/components/App/ShareStatsChart";
import UdpateShareLinkStatus from "@/view/components/App/UpdateShareLinkStatus";

export default {
  name: "app-share-link",
  components: {
    UdpateShareLinkStatus,
    ShareStatsChart,
    ShareAnalytics,
    VueReadmore,
    ShareLinkStepper,
    ShareLinkModal,
  },
  STATUS: {
    1: {
      title: "Enabled",
      color: "success",
      text: "Enabled",
    },
    3: {
      title: "Disabled ",
      color: "orange",
      text: "Disabled",
    },
    2: {
      title: "Expired",
      color: "red",
      text: "Expired",
    },
    4: {
      title: "Maintenance ",
      color: "purple",
      text: "Maintenance",
    },
    5: {
      title: "Coming soon",
      color: "primary",
      text: "Coming soon",
    },
  },
  RELEASE_STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      release: "android",
      readMore: false,
      updateShareStatusModal: false,
      previewItems: [
        { title: "Maintenance mode", value: "maintenance" },
        { title: "Coming soon mode", value: "coming_soon" },
        { title: "Not available", value: "not_available" },
      ],
      preview: false,
      defaultSelectedTab: 0,
      public_access: false,
      page: 1,
      appId: "",
      shareId: "",
      openShareLinkModal: false,
      openGenerateLinkForm: false,
      errorMessage: null,
      selectedId: "",
      shareableLinks: [],
      loading: false,
      busy: false,
      headers: [
        {
          text: "Android",
          align: "start",
          width: "15%",
          sortable: false,
          value: "android",
        },
        {
          text: "iOS",
          align: "start",
          width: "15%",
          sortable: false,
          value: "ios",
        },
        {
          text: "Views",
          align: "start",
          sortable: false,
          width: "5%",
          value: "views",
        },
        {
          text: "Installs",
          align: "start",
          sortable: false,
          width: "5%",
          value: "install",
        },
        {
          text: "Feedback",
          align: "start",
          sortable: false,
          width: "5%",
          value: "feedback",
        },
        {
          text: "Subscribers",
          align: "start",
          sortable: false,
          width: "5%",
          value: "subscribe",
        },
        {
          text: "Link",
          sortable: false,
          width: "9%",
          value: "copy_link",
        },
        {
          text: "Expire",
          align: "start",
          sortable: false,
          width: "15%",
          value: "expiry",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        // {
        //   text: "Status",
        //   align: "end",
        //   sortable: false,
        //   width: "10%",
        //   value: "status",
        // },
        {
          text: "",
          align: "end",
          sortable: false,
          width: "10%",
          value: "action",
        },
      ],
      expanded: [],
      openDeleteModal: false,
      showAnalyticsDrawer: false,
      selectedData: {},
    };
  },
  computed: {
    ...mapGetters({
      getShareableLinks: "getShareableLinks",
      appInfo: "getAppInfo",
      getTotalShareableLinksCount: "getTotalShareableLinksCount",
      getTotalShareableLinks: "getTotalShareableLinks",
    }),
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalShareableLinks.length > oldVal * 10) {
          this.setPreviousData(
            this.getTotalShareableLinks.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId =
            this.getShareableLinks[this.getShareableLinks.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousData(this.getTotalShareableLinks.slice(offset, limit));
      }
    },
  },
  created() {
    this.appId = this.$route.params.app_id;
    if (this.canManageApp) {
      this.loadData();
    }
    this.public_access = this.appInfo.public;
  },
  methods: {
    ...mapMutations({
      setPreviousData: "setPreviousData",
      removeShareLinkDeletedData: "removeShareLinkDeletedData",
      updateAppShareStatus: "updateAppShareStatus",
    }),
    closeModal() {
      this.selectedData = {};
      this.openGenerateLinkForm = false;
      this.showAnalyticsDrawer = false;
    },
    openAnalyticsDrawer(item, tab) {
      this.selectedData = item;
      this.defaultSelectedTab = tab;
      this.showAnalyticsDrawer = true;
    },
    openSetting() {
      if (!this.public_access) {
        this.notifyErrorMessage(
          "Please enable the Public Access for this app first. You can find it at the top of this page"
        );
      } else {
        this.openGenerateLinkForm = true;
      }
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
    afterSuccess(detail) {
      this.shareId = detail.uid;
      this.openShareLinkModal = true;
      this.loadData();
    },
    afterUpdated() {
      this.selectedData = null;
      this.openGenerateLinkForm = false;
      this.loadData();
    },
    loadData(lastId) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_SHARE_LINK_LIST, {
          app_id: this.appId,
          lastId: lastId || "",
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    updatePublicAccess() {
      this.$store
        .dispatch(UPDATE_APP_PUBLIC_STATUS, {
          app_id: this.$route.params.app_id,
          enabled: this.public_access,
        })
        .then(() => {
          this.updateAppShareStatus(this.public_access);
          // this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.public_access = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openEditDialog(detail) {
      this.selectedData = detail;
      this.openGenerateLinkForm = true;
    },
    openChangeStatus(detail) {
      this.selectedData = detail;
      this.updateShareStatusModal = true;
    },
    openDeleteDialog(detail) {
      this.selectedData = detail;
      this.openDeleteModal = true;
    },
    openLink(uid) {
      window.open(`${location.origin}/apps/install/${uid}`, "_blank");
    },
    deleteShareableLink() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DELETE_SHAREABLE_LINK, {
          app_id: this.$route.params.app_id,
          link_id: this.selectedData.id,
        })
        .then((response) => {
          this.busy = false;
          this.openDeleteModal = false;
          this.removeShareLinkDeletedData({ id: this.selectedData.id });
          this.selectedData = null;
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>
