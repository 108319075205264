var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('div',[_c('div',{staticClass:"text-muted bold-text text-body-1"},[_vm._v(" Installs collected from this link "),_c('div',[_c('small',[_vm._v("TestFlight installs are not counted")])])])])]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"transparent",attrs:{"type":"table-thead, table-tbody"}}):_vm._e(),(!_vm.loading)?_c('v-data-table',{staticClass:"hide-v-data-footer__select release-table",attrs:{"headers":_vm.headers,"items":_vm.getInstallList,"page":_vm.page,"server-items-length":_vm.getTotalInstallCount},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.device",fn:function(ref){
var item = ref.item;
return [_c('user-agent-parser',{attrs:{"full":"","default-device":item.device}})]}},{key:"item.release",fn:function(ref){
var item = ref.item;
return [(item.release)?_c('div',{attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$options.RELEASE_STATUS[item.release.status].color},domProps:{"textContent":_vm._s(
                    item.release.platform === 'android'
                      ? 'mdi-android'
                      : 'mdi-apple'
                  )}}),_vm._v(" v"),_c('MaxText',{attrs:{"text":item.release.version,"max":"30"}})],1)]}}],null,true)},[_c('span',[_c('ul',[_c('li',[_vm._v(" Status: "+_vm._s(_vm.$options.RELEASE_STATUS[item.release.status].title)+" ")]),_c('li',[_vm._v(" Version: "+_vm._s(item.release.version)+" ")]),_c('li',[_vm._v(" Version Code: "+_vm._s(item.release.version_code)+" ")]),_c('li',[_vm._v(" Size: "+_vm._s(_vm.formatBytes(item.release.size))+" ")])])])])],1):_c('div',[_vm._v("-")])]}},{key:"item.country",fn:function(ref){
                  var item = ref.item;
return [_c('country-city',{attrs:{"detail":item}})]}},{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_c('vue-hoverable-date',{attrs:{"date":item.created_at}})]}},{key:"footer.page-text",fn:function(){return [_c('div',[_vm._v(" Updated every minute "),_c('v-btn',{staticClass:"text-transform-none",attrs:{"text":""},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',{attrs:{"color":"grey","left":""},domProps:{"textContent":_vm._s('mdi-reload')}}),_vm._v(" Reload ")],1),_vm._v(" Total: "),_c('b',[_vm._v(_vm._s(_vm.getTotalInstallCount))])],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.canManageApp)?_c('no-data',{attrs:{"first-text":"No installs or downloads","second-text":"You don't have the permission to view the installs or downloads"}}):_c('no-data',{attrs:{"first-text":"No installs or downloads logged for this link","second-text":"Install logs can be useful to trace the devices that installed your release for debugging purposes"}})],1)],1)]},proxy:true}],null,false,369356247)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }