<template>
  <v-card outlined>
    <v-card-title>
      <div>
        <div class="text-muted bold-text text-body-1">
          Installs collected from this link
          <div><small>TestFlight installs are not counted</small></div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0" cols="12">
      <v-skeleton-loader
        class="transparent"
        type="table-thead, table-tbody"
        v-if="loading"
      />
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="getInstallList"
        :page.sync="page"
        class="hide-v-data-footer__select release-table"
        :server-items-length="getTotalInstallCount"
      >
        <template #item.device="{ item }">
          <user-agent-parser
            full
            :default-device="item.device"
          ></user-agent-parser>
        </template>
        <template #item.release="{ item }">
          <div align="left" v-if="item.release">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <v-icon
                    small
                    left
                    :color="$options.RELEASE_STATUS[item.release.status].color"
                    v-text="
                      item.release.platform === 'android'
                        ? 'mdi-android'
                        : 'mdi-apple'
                    "
                  ></v-icon>

                  v<MaxText :text="item.release.version" max="30" />
                </span>
              </template>
              <span>
                <ul>
                  <li>
                    Status:
                    {{ $options.RELEASE_STATUS[item.release.status].title }}
                  </li>
                  <li>
                    Version:
                    {{ item.release.version }}
                  </li>
                  <li>
                    Version Code:
                    {{ item.release.version_code }}
                  </li>
                  <li>
                    Size:
                    {{ formatBytes(item.release.size) }}
                  </li>
                </ul>
              </span>
            </v-tooltip>
          </div>
          <!-- No release selected -->
          <div v-else>-</div>
        </template>
        <template #item.country="{ item }">
          <country-city :detail="item"></country-city>
        </template>
        <template #item.date="{ item }">
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </template>
        <template #footer.page-text>
          <div>
            Updated every minute
            <v-btn class="text-transform-none" @click="loadData()" text>
              <v-icon v-text="'mdi-reload'" color="grey" left> </v-icon>
              Reload
            </v-btn>
            Total: <b>{{ getTotalInstallCount }}</b>
          </div>
        </template>
        <template #no-data>
          <v-row>
            <v-col cols="12">
              <no-data
                v-if="!canManageApp"
                first-text="No installs or downloads"
                second-text="You don't have the permission to view the installs or downloads"
              ></no-data>
              <no-data
                v-else
                first-text="No installs or downloads logged for this link"
                second-text="Install logs can be useful to trace the devices that installed your release for debugging purposes"
              ></no-data>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { GET_SHARE_INSTALL_LIST } from "@/store/releases/releases.module";
import { get, isEmpty, formatBytes } from "@/core/services/helper.service";
import UserAgentParser from "@/view/components/Common/UserAgentParser";
import CountryCity from "@/view/components/Common/CountryCity";

export default {
  components: { CountryCity, UserAgentParser },
  props: {
    shareId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
      page: 1,
      loading: false,
      headers: [
        {
          text: "Device",
          align: "start",
          width: "15%",
          sortable: false,
          value: "device",
        },
        {
          text: "Release",
          align: "start",
          sortable: false,
          width: "15%",
          value: "release",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          width: "15%",
          value: "country",
        },
        {
          text: "Date",
          align: "end",
          sortable: false,
          width: "5%",
          value: "date",
        },
      ],
    };
  },
  RELEASE_STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  computed: {
    ...mapGetters({
      getInstallList: "getInstallList",
      appInfo: "getAppInfo",
      getTotalInstallCount: "getTotalInstallCount",
      getTotalInstallList: "getTotalViewsList",
    }),
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalInstallList.length > oldVal * 10) {
          this.setPreviousInstallList(
            this.getTotalInstallList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.getInstallList[this.getInstallList.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousInstallList(
          this.getTotalInstallList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapMutations({
      setPreviousInstallList: "setPreviousInstallList",
    }),
    loadData(lastId) {
      if (this.loading) return;
      this.loading = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: this.shareId,
        lastId: lastId || "",
      };
      this.$store
        .dispatch(GET_SHARE_INSTALL_LIST, payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
