<template>
  <v-row>
    <v-col class="text-center" cols="12">
      <div class="titleFont">Stats for the selected public link</div>
    </v-col>
    <v-col cols="12">
      <div v-if="loading">
        <v-skeleton-loader
          type="list-item-avatar, divider, list-item-three-line, card-heading"
        ></v-skeleton-loader>
      </div>
      <v-row v-else>
        <v-col cols="12" md="6">
          <line-charts
            v-if="!loading"
            class="mt-n10"
            display-legend
            :chart-data="downloadInstallChartData"
          ></line-charts>
        </v-col>
        <v-col cols="12" md="6">
          <line-charts
            v-if="!busy"
            class="mt-n10"
            display-legend
            :chart-data="feedbackSubscriberChartData"
          ></line-charts>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tab">
        <v-tab> Views </v-tab>
        <v-tab> Installs </v-tab>
        <v-tab class="mx-3"> Feedback </v-tab>
        <v-tab> Subscribers </v-tab>
        <v-tab-item>
          <share-views :share-id="shareId"></share-views>
        </v-tab-item>
        <v-tab-item>
          <share-install :share-id="shareId"></share-install>
        </v-tab-item>
        <v-tab-item>
          <release-feedback :share-id="shareId" from-share></release-feedback>
        </v-tab-item>
        <v-tab-item>
          <subscribers-list :share-id="shareId" from-share></subscribers-list>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import { GET_SHARE_CHART_LIST } from "@/store/releases/releases.module";
import LineCharts from "@/view/components/Common/Charts/LineCharts.vue";
import ShareViews from "@/view/components/Releases/ShareViews";
import ShareInstall from "@/view/components/Releases/ShareInstall";
import ReleaseFeedback from "@/view/components/App/ReleaseFeedback";
import SubscribersList from "@/view/components/App/SubscribersList";

export default {
  props: {
    shareId: {
      type: String,
      default: "",
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SubscribersList,
    ReleaseFeedback,
    ShareInstall,
    LineCharts,
    ShareViews,
  },
  data() {
    return {
      loading: false,
      tab: 0,
      busy: false,
      feedbackSubscriberChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "External Feedback",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(129, 199, 132)",
            tension: 0.1,
          },
          {
            label: "Subscribers",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(220, 231, 117)",
            tension: 0.1,
          },
        ],
      },
      downloadInstallChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "External Installs",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(144, 164, 174)",
            tension: 0.1,
          },
          {
            label: "Views",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(255, 183, 77)",
            tension: 0.1,
          },
        ],
      },
    };
  },
  created() {
    this.getViewsAndInstallChart();
    this.getFeedbackAndSubscriberChart();
    if (this.selectedTab) {
      this.tab = this.selectedTab;
    }
  },
  methods: {
    getViewsAndInstallChart() {
      if (this.loading) return;
      this.loading = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: this.shareId,
        type: "views_installs",
      };
      this.$store
        .dispatch(GET_SHARE_CHART_LIST, payload)
        .then((data) => {
          this.setChartData(data.results ? data.results : {});
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    getFeedbackAndSubscriberChart() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        app_id: this.$route.params.app_id,
        link_id: this.shareId,
        type: "feedback_subscribers",
      };
      this.$store
        .dispatch(GET_SHARE_CHART_LIST, payload)
        .then((data) => {
          this.setSubscriberChartData(data.results ? data.results : {});
          this.$nextTick(() => {
            this.busy = false;
          });
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    setChartData(results) {
      if (results && results.installs) {
        results.installs.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.downloadInstallChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.downloadInstallChartData.datasets[0].data[month] =
                item.count;
            }
          });
        });
      }
      if (results && results.views) {
        results.views.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.downloadInstallChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.downloadInstallChartData.datasets[1].data[month] =
                item.count;
            }
          });
        });
      }
    },
    setSubscriberChartData(results) {
      if (results && results.feedback) {
        results.feedback.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.feedbackSubscriberChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.feedbackSubscriberChartData.datasets[0].data[month] =
                item.count;
            }
          });
        });
      }
      if (results && results.views) {
        results.subscribers.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.feedbackSubscriberChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.feedbackSubscriberChartData.datasets[1].data[month] =
                item.count;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
