<template>
  <v-row>
    <v-col cols="12" class="mt-4 bodyFont bold-text">
      Update Page Status
    </v-col>
    <v-col cols="12">
      <v-select
        filled
        item-text="title"
        item-value="value"
        dense
        :items="previewItems"
        v-model="formValues.show_as"
      >
        <template slot="item" slot-scope="data">
          <v-list-item-title id="data-item">
            {{ data.item.title }}
          </v-list-item-title>
        </template>
      </v-select>
    </v-col>
    <v-col v-if="[4, 5].includes(formValues.show_as)" cols="12">
      <v-text-field
        v-model="formValues.show_as_message"
        class="mt-n4"
        :placeholder="
          formValues.show_as === 4
            ? 'Ex: We are doing some maintenance...'
            : 'Ex: Our app will be available soon...'
        "
        v-validate="'required|min:3|max:70'"
        v-bind="veeValidate('Message', '')"
        filled
        label="Custom Message"
        hint="Optional - By default, we display a message but you can choose a custom one to be displayed at top of the page for the external testers."
        counter="70"
        persistent-hint
      ></v-text-field>
    </v-col>
    <v-col class="text-center">
      <v-btn
        @click="submitDetail"
        class="text-transform-none"
        color="primary"
        :loading="busy"
        depressed
      >
        Save
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { UPDATE_SHARE_LINK_STATUS } from "@/store/releases/releases.module";
import veeValidate from "@/mixins/veeValidate";
import { mapMutations } from "vuex";
export default {
  mixins: [veeValidate],
  props: {
    selectedDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formValues: {
        show_as: "",
        show_as_message: "",
        enabled: false,
      },
      openDisablePopup: false,
      busy: false,
      previewItems: [
        { title: "Enabled", value: 1 },
        { title: "Disabled", value: 3 },
        { title: "Maintenance mode", value: 4 },
        { title: "Coming soon mode", value: 5 },
      ],
    };
  },
  watch: {
    "formValues.show_as"(val) {
      if ([1, 3].includes(val)) {
        this.formValues.show_as_message = "";
      }
    },
    selectedDetail(val) {
      this.formValues.show_as = val.status;
      this.formValues.show_as_message = val.settings.custom_message;
    },
  },
  mounted() {
    this.formValues.show_as = this.selectedDetail.status;
    this.formValues.show_as_message =
      this.selectedDetail.settings.custom_message;
  },
  methods: {
    ...mapMutations({
      updateShareListStatus: "updateShareListStatus",
    }),
    submitDetail() {
      if (this.busy) return;
      this.busy = true;
      const dataToPost = {
        app_id: this.$route.params.app_id,
        share_id: this.selectedDetail.id,
        status: this.formValues.show_as,
        message: this.formValues.show_as_message,
      };
      this.$store
        .dispatch(UPDATE_SHARE_LINK_STATUS, dataToPost)
        .then((response) => {
          this.busy = false;
          this.$emit("close");
          this.updateShareListStatus({
            id: dataToPost.share_id,
            message: dataToPost.message,
            status: dataToPost.status,
          });
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style scoped></style>
